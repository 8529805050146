import React from 'react'
import {useStaticQuery, graphql, Link} from 'gatsby'

const LatestBlogPosts = () => {
    const data = useStaticQuery(graphql`
    query {
        allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, limit: 2) {
            edges {
              node {
                excerpt
                fields {
                  slug
                }
                frontmatter {
                  date(formatString: "MMMM DD, YYYY")
                  title
                }
              }
            }
        }
    }
    `)
        const blogPosts = data.allMarkdownRemark.edges
        console.log(blogPosts);
        return (
            <div class="column">
                <span className="title">Latest Blog Posts</span>
            <section className="section">
                <div className="container">
                    {blogPosts.map(({node}) => {
                        return (
                            <Link to={node.fields.slug}>
                            <div key={node.frontmatter.title}>
                            <span className="content is-medium">{node.frontmatter.title}</span>
                            <p>{node.frontmatter.date}</p>
                            <div className="content is-medium">{node.excerpt}</div>
                            <p></p>
                            </div>
                            </Link>
                        )
                    })}
                    <h1 className="title"></h1>
                </div>
            </section>
            </div>
            // <div className="column">
            //     <p className="title">Latest Blog Posts</p>
            //     {blogPosts.map(({node}) => {
            //         return(
            //         <div key={node.frontmatter.title}>
            //         <Link className="subtitle" to={node.fields.slug}>{node.frontmatter.title}</Link>
            //         <p><small>{node.frontmatter.date}</small></p>
            //         <p>{node.frontmatter.description || node.excerpt}</p>
            //         </div>
            //         )
            //     })}
            //     </div>
        )
    }
export default LatestBlogPosts