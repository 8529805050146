import React from "react";
import {useStaticQuery, graphql} from "gatsby";


const ReadingList = () => {
        const data = useStaticQuery(graphql`
        query {
            allBooksJson(
                sort: {
                    fields: [title]
                    order: ASC
                }
            ) {
                edges {
                    node {
                        title,
                        author,
                        image,
                        link
                    }
                }
            }
          }
        `)
        const books = data.allBooksJson.edges;
        console.log(books);
        return (
            <div className="column">
            <p className="title">Current Reading List</p>
            <div className="columns">
                {books.map(({ node }) => {
                    const title = node.title
                    return(
                        <div key={node.title} className="column">
                        <a href={node.link}>
                        <img src={node.image}/>
                        <span className="subtitle">{title}</span>
                        <p>{node.author}</p>
                        </a>
                        </div>
                    )
                })}
            </div>
            </div>
        )
    }
export default ReadingList