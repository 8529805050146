import React from "react"
import SEO from "../components/seo"
import ReadingList from "../components/readinglist"
import {Link, graphql} from "gatsby"
import Layout from "../components/layout"
import LatestBlogPosts from "../components/latestblogposts"

class Home extends React.Component {
render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    return(
        <Layout location={this.props.location} title={siteTitle}>
            <SEO title="Home" />
            <div className="column">
            <h1 className="title">Welcome</h1>
            <span>Thanks for stopping by. I'm a software developer who helps companies build products for people - not users, and more often then not it starts within the company.
                I believe that companies who don't embrace the people over users principle is also not really engaging their developers and that is going to in the end create lower
                quality software. So, software is a people business just as much as it is bits and bytes. Treat people with respect, knock down their walls and let them run you will be
                amazed what they can do! I hope that while you are here you will find something; insightful, useful, or funny.
            </span>
            </div>
            <LatestBlogPosts></LatestBlogPosts>
            <ReadingList></ReadingList>
        </Layout>
    )
}
}
export default Home

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`